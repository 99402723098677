<template>
 <div>
  <b-card bg-variant="light">
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <b-form-group 
        label-cols-lg="3"
        label="Authenticate Login"
        label-size="lg"
        label-class="font-weight-bold pt-0"
        class="mb-0"
      >
        <b-form-group
          label="Email Address:"
          label-for="nested-email-address"
          label-cols-sm="3"
          label-align-sm="right"
        >
          <b-form-input id="nested-email-address"
            v-model="user.email"
          ></b-form-input>
        </b-form-group>
        <ValidationProvider rules="required" name="Password" vid="password">
          <b-form-group 
            label-cols-sm="3"
            label-align-sm="right"
            slot-scope="{ valid, errors }"
            label="Password">      
              <b-form-input
                id="nested-password"
                type="password"
                v-model="user.password"
                :state="errors[0] ? false : (valid ? true : null)"
                placeholder="Enter password">
              </b-form-input>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-form-group>
      <br>
      <b-row>
        <b-col md="4">
          <b-button type="submit" block variant="primary">Submit</b-button>
        </b-col>
        <b-col md="4">
          <b-button type="reset" block variant="outline-secondary">Reset</b-button>
        </b-col>
        <b-col md="4">
          <b-button :disabled="disabledResend" @click="resend" block variant="primary">Resend</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-link to="/password-forgot">Forgot Password</b-link>
        </b-col>
        <b-col md="6">
          <b-link to="/password-reset">Reset Password</b-link>
        </b-col>
      </b-row>
    </b-form>

  </b-card>
 </div>
</template>

<script>
import {http_v1} from '@/axios'

export default {
  data () {
    return {
      user:{
        email: '',
        password: '',
      },
      show: true,
      can_resend: false
    }
  },
  computed: {
    disabledResend() {
      return this.can_resend == false
    }
  },
  methods: {
    resend() {
      http_v1.post (
        'email_confirmation_resend', {
          email: this.user.email,
          confirmed_url:  window.location.origin /* + this.$route.path */ + '/welcome'
        }
      ).then(response => {
        if(response.data) {
          var message = response.data.message || JSON.stringify(response.data)
          this.$bvToast.toast(message, {
            title: 'Resent',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      }).catch(error => {
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    },
    onSubmit(event) {
      event.preventDefault()
      http_v1.post (
        // 'auth/login'
        'login', {
          email: this.user.email,
          password: this.user.password
        }
      ).then(response => {
        if(response.data && response.data.auth_token) {
          localStorage.token = response.data.auth_token
          this.$store.dispatch('user/set_current', response.data.user)
          this.$store.dispatch('user/set_roles', response.data.actor_roles )
          this.$router.back()
        }
      }).catch(error => {
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
          if (error.response.status === 401 && message === 'Email address has not been verified.') {
            this.can_resend = true
          }
        }
      })
    },
    onReset(event) {
      event.preventDefault()
      // Reset our form values
      this.user.email = ''
      this.user.password = ''
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  }
}
</script>